// Gatsby supports TypeScript natively!
import React from "react"
import {PageProps, Link} from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Address from "../components/address"


const Privacy = (props: PageProps) => (
    <Layout>
        <SEO title="KPF.NRW Datenschutz"/>
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="relative px-4 sm:px-6 lg:px-8">

                <div className="text-lg max-w-prose mx-auto">
                     <h1>
            <span className="block text-base text-center text-tefaf font-semibold tracking-wide uppercase">
              Pflichtangaben
            </span>
                        <span
                            className="mt-8 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Datenschutzerklärung
            </span>
                    </h1>
                    <span
                        className="mt-8 block text-1xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
              Verantwortlich im Sinne des Datenschutzgesetzes:
                    </span>
                    <Address />
                    <span
                        className="mt-8 block text-1xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
    Allgemeines zur Datenverarbeitung </span>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        <span className="font-bold">1. Umfang der Verarbeitung personenbezogener Daten</span> <br/>
                        Wir verarbeiten personenbezogene Daten unserer Nutzer grundsätzlich nur, soweit dies zur
                        Bereitstellung einer
                        funktionsfähigen Website sowie unserer Inhalte und Leistungen erforderlich ist. Die Verarbeitung
                        personenbezogener
                        Daten unserer Nutzer erfolgt regelmäßig nur nach Einwilligung des Nutzers. Eine Ausnahme gilt in
                        solchen Fällen,
                        in denen eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich ist
                        und die
                        Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist.
                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        <span
                            className="font-bold">2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten</span>
                        <br/>
                        Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen
                        Person
                        einholen, dient Art. 6 Abs. 1 lit. a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.
                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens
                        oder eines Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten
                        des Betroffenen das erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO
                        als Rechtsgrundlage für die Verarbeitung.
                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        <span className="font-bold">3. Datenlöschung und Speicherdauer</span> <br/>
                        Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald
                        der Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus erfolgen,
                        wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen,
                        Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde.
                        Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen
                        vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren
                        Speicherung der
                        Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.
                    </p>

                    <span
                        className="mt-8 block text-1xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
    Bereitstellung der Website und Erstellung von Logfiles </span>

                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        <span className="font-bold">1. Beschreibung und Umfang der Datenverarbeitung</span> <br/>
                        Bei jedem Aufruf unserer Internetseite erfasst unser System automatisiert Daten und
                        Informationen vom Computersystem
                        des aufrufenden Rechners.
                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        <span className="font-bold">Folgende Daten werden hierbei erhoben:</span>
                        <ul className="list-disc">
                            <li>Informationen über den Browsertyp und die verwendete Version</li>
                            <li>Das Betriebssystem des Nutzers</li>
                            <li>Die IP-Adresse des Nutzers</li>
                            <li>Datum und Uhrzeit des Zugriffs</li>
                            <li>Websites, von denen das System des Nutzers auf unsere Internetseite gelangt</li>
                            <li>Websites, die vom System des Nutzers über unsere Website aufgerufen werden</li>
                        </ul>

                        Die Daten werden ebenfalls in den Logfiles unseres Systems gespeichert. Eine Speicherung dieser
                        Daten zusammen mit
                        anderen personenbezogenen Daten des Nutzers findet nicht statt.
                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        <span className="font-bold">2. Rechtsgrundlage für die Datenverarbeitung </span><br/>

                        Rechtsgrundlage für die vorübergehende Speicherung der Daten und der Logfiles ist Art. 6
                        Abs. 1 lit. f DSGVO.
                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        <span className="font-bold">3. Zweck der Datenverarbeitung</span><br/>

                        Die vorübergehende Speicherung der IP-Adresse durch das System ist notwendig, um eine
                        Auslieferung der
                        Website an den Rechner des Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers
                        für die Dauer der Sitzung gespeichert bleiben.

                        Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der Website sicherzustellen.
                        Zudem dienen uns die Daten zur Optimierung der Website und zur Sicherstellung der Sicherheit
                        unserer
                        informationstechnischen Systeme. Eine Auswertung der Daten zu Marketingzwecken findet in
                        diesem Zusammenhang nicht statt.

                        In diesen Zwecken liegt auch unser berechtigtes Interesse an der Datenverarbeitung nach Art.
                        6 Abs. 1 lit. f DSGVO.
                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        <span className="font-bold">4. Dauer der Speicherung</span><br/>

                        Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht
                        mehr erforderlich sind.
                        Im Falle der Erfassung der Daten zur Bereitstellung der Website ist dies der Fall, wenn die
                        jeweilige Sitzung beendet ist.

                        Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens sieben Tagen der
                        Fall. Eine darüberhinausgehende
                        Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer gelöscht oder
                        verfremdet,
                        sodass eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.
                    </p>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        <span className="font-bold">5. Widerspruchs- und Beseitigungsmöglichkeit</span> <br/>
                        Die Erfassung der Daten zur Bereitstellung der Website und die Speicherung der Daten in Logfiles
                        ist für den Betrieb der Internetseite zwingend erforderlich.
                        Es besteht folglich seitens des Nutzers keine Widerspruchsmöglichkeit.
                    </p>
                    <span
                        className="mt-8 block text-1xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
    Änderung der Datenschutzbestimmungen </span>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        Unsere Datenschutzerklärung können in unregelmäßigen Abständen angepasst werden, damit sie
                        den aktuellen rechtlichen
                        Anforderungen entsprechen oder um Änderungen unserer Dienstleistungen umzusetzen, z. B. bei
                        der Einfügung
                        neuer Angebote. Für Ihren nächsten Besuch gilt dann automatisch die neue
                        Datenschutzerklärung.
                    </p>
                </div>
            </div>
        </div>
    </Layout>
)

export default Privacy
