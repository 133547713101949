import {MailIcon, OfficeBuildingIcon, PhoneIcon, UserIcon} from "@heroicons/react/outline";
import React from "react";

function Address() {
    return (
        <>
        <div className="mt-9">
            <div className="flex">
                <div className="flex-shrink-0">
                    <OfficeBuildingIcon className="h-6 w-6 text-tefaf" aria-hidden="true"/>
                </div>
                <div className="flex-shrink-0">

                    <p className="ml-3 text-base text-gray-500">
                        Koordinationsstelle für Provenienzforschung <br/>
                        in Nordrhein-Westfalen (KPF.NRW)
                    </p>
                    <p className="ml-3 text-base text-gray-500">
                        Bachstraße 5-9
                    </p>
                    <p className="ml-3 text-base text-gray-500">
                        53115 Bonn
                    </p>
                </div>
            </div>
            <div className="mt-9">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <PhoneIcon className="h-6 w-6 text-tefaf" aria-hidden="true"/>
                    </div>
                    <div className="ml-3 text-base text-gray-500">
                        0228-2070-160
                    </div>
                </div>
                <div className="flex">
                    <div className="h-6 w-6 text-tefaf" aria-hidden="true">Fax</div>
                    <div className="ml-3 text-base text-gray-500">
                        0228-2070-299
                    </div>
                </div>
                <div className="mt-6 flex">
                    <div className="flex-shrink-0">
                        <MailIcon className="h-6 w-6 text-tefaf" aria-hidden="true"/>
                    </div>
                    <div className="ml-3 text-base text-gray-500">
                        <p>kontakt@kpf.nrw</p>
                    </div>
                </div>
                <div className="flex">
                    <UserIcon className="h-6 w-6 text-tefaf" aria-hidden="true"/>
                    <div className="ml-3 text-base text-gray-500">
                        Jasmin Hartmann
                    </div>
                </div>
            </div>
        </div>
            </>
            )
            }
            export default Address
